import React from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import LightGallery from "lightgallery/react";

const PolicyText = () => {
    return (
        <div className="container mt-5 mb-5">
            <div className="row justify-content-center gutter-1">
                <div className="col-lg-8">
                    <div className="bg-white p-2">
                        <h2>Политика конфиденциальности</h2>
                        <p><b>1. Общие положения</b>
                            <br/>
                            Настоящая Политика конфиденциальности (далее — "Политика") определяет порядок обработки и защиты персональных данных пользователей сайта https://dveri-arsenal.ru/ (далее — "Сайт").
                            <br/>
                            Использование Сайта означает согласие пользователя с данной Политикой.
                        </p>
                        <p>
                            <b>2. Сбор и обработка персональных данных</b>
                            <br/>
                            При использовании Сайта мы можем собирать и обрабатывать следующие персональные данные пользователей:
                            <ul className='ulDot'>
                                <li>Имя</li>
                                <li>Адрес электронной почты (email)</li>
                                <li>Номер телефона</li>
                            </ul>
                            Данные предоставляются пользователями добровольно при заполнении форм на Сайте.
                        </p>
                        <p>
                            <b>3. Цели сбора персональных данных</b>
                            <br/>
                            Собранные данные используются исключительно в следующих целях:
                            <ul className='ulDot'>
                                <li>Обратная связь с пользователями</li>
                                <li>Консультирование и обработка заявок</li>
                                <li>Улучшение работы Сайта и предоставляемых услуг</li>
                            </ul>
                        </p>
                        <p>
                            <b>4. Использование инструментов аналитики</b>
                            <br/>
                            На Сайте используется сервис веб-аналитики "Яндекс.Метрика", который автоматически собирает обезличенные данные о поведении пользователей для улучшения работы Сайта.
                        </p>
                        <p>
                            <b>5. Хранение и защита данных</b>
                            <br/>
                            Персональные данные пользователей хранятся в защищённых системах и не передаются третьим лицам.
                        </p>
                        <p>
                            <b>6. Изменения в Политике</b>
                            <br/>
                            Администрация Сайта оставляет за собой право изменять данную Политику без предварительного уведомления пользователей. Актуальная версия всегда доступна на Сайте.
                        </p>
                        <p>
                            <b>7. Контактная информация</b>
                            <br/>
                            По всем вопросам, связанным с обработкой персональных данных, можно связаться с нами через контактную информацию, указанную на Сайте.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PolicyText;